<template>
    <div class="mountinesListCard">
        <!-- 表格 -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :row-key="getRowKeyOfTarget"
        >
          <el-table-column type="selection" reserve-selection width="55"></el-table-column>
          <el-table-column prop="accessoriesNo" label="配件编号"></el-table-column>
          <el-table-column label="配件图片" width="120">
            <template slot-scope="scope">
              <img :src="scope.row.imageUrlList[0].url" alt="" width="60" height="40" />
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" prop="name" label="配件名称"></el-table-column>
          <el-table-column prop="price" label="价格(元)" sortable></el-table-column>
          <el-table-column prop="inventory" label="库存" sortable>
            <template slot-scope="scope">
              <div>{{scope.row.inventory}} {{scope.row.units}}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true" prop="createTime" label="创建时间" sortable></el-table-column>
          <el-table-column label="操作" align="center" width="90px">
            <template slot-scope="scope">
              <el-button type="text" size="small"  @click="handleClickEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small"  @click="handleClickDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="page-wraps">
          <el-button size="small" @click="delChosse">删除选中</el-button>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currpage"
            :page-sizes="[5, 10, 20, 50, 100]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalRow"
          >
          </el-pagination>
        </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      currpage: 1,
      pagesize: 5,
      totalRow: 0,
      delIdList: [],
      keyword: null,
      startDate: null,
      endDate: null,
      minPrice: null,
      maxPrice: null,
    };
  },
  mounted(){
    this.getAccessoriesList();
  },
  methods: {
    setValue(value){
      this.keyword = value.goodsName + value.goodsNum;
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.minPrice = value.minPrice;
      this.maxPrice = value.maxPrice;
      this.getAccessoriesList()
    },
    // 删除选中的
    delChosse(){
      if(this.delIdList.length == 0){
        this.$message.warning("请选需要删除的列表")
      }else{
        let ids = []
        this.delIdList.forEach(v =>{
          ids.push(v.id)
        })
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            ids: ids,
          }
          this.$API.deleteAccessories(params).then( res => {
              if(res.code === 0){
                  this.$message.success("删除成功");
                  this.getAccessoriesList();
              }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
      });
      }
      
    },
    // 编辑配件
    handleClickEdit(row){
      this.$router.push({ name: "newMountings", query: { id: row.id } });
    },
    // 删除配件
    handleClickDel(row){
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = [];
          ids.push(row.id)
          let params = {
            ids: ids,
          }
          this.$API.deleteAccessories(params).then( res => {
              if(res.code === 0){
                  this.$message.success("删除成功");
                  this.getAccessoriesList();
              }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
      });
    },
    // 获取配件列表
    getAccessoriesList(){
      let params = {
        keyword: this.keyword,
        currentPage: this.currpage,
        pageSize: this.pagesize,
        startDate: this.startDate,
        endDate: this.endDate,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      }
      this.$API.getAccessoriesList(params).then( res => {
          if(res.code === 0){
            this.tableData = res.data.list;
            this.totalRow = res.data.pageInfo.totalRow
          }
      })
    },
    getRowKeyOfTarget(row) {
      return row.id;
    },
    // 当前页
    handleCurrentChange(cpage) {
      this.currpage = cpage;
      this.getAccessoriesList();
    },
    // 页码
    handleSizeChange(psize) {
      this.pagesize = psize;
      this.getAccessoriesList();
    },
    // 删除选择-多选
    handleSelectionChange(val) {
      this.delIdList = val
    },
  },
};
</script>
<style lang="scss" >
.mountinesListCard {
    margin-top: 10px;
    position: relative;
    .u-f-ac{
      display: flex;
      flex-flow: wrap;
      align-items: center;
      .bt {
          padding: 0 20px;
          color: rgb(63, 62, 62);
          background-color: rgb(255, 255, 255);
          height: 32px;
          border-radius: 4px;
          font-size: 12px;
          margin: 0 8px;
          border: 1px solid rgb(182, 181, 181);
      }
      .bt:hover {
          background-color: rgb(248, 246, 246);
      }
      .bt:active {
          background-color: rgb(236, 233, 233);
      }
  }
  .page-wraps {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  }
}
</style>